<template>
  <div id="employees" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5"
        >MANTENIMIENTO DE EMPLEADOS</v-row
      >
      <v-row no-gutters justify="center" class="mr-12 mb-4">
        <v-col
          cols="12"
          lg="6"
          md="6"
          sm="12"
          xs="12"
          class="selectEmpresa"
          align-self="end"
        >
          <v-row class="ml-12">
            <label for="enterprise" class="ml-5 mr-2">Empresa Pagadora</label>
            <select
              name="enterprise"
              id="enterprise"
              v-model="enterprise"
              @change="obtenerEmpresa()"
            >
              <option selected disabled value="0">Selecciona Empresa</option>
              <option
                v-for="enterprise in enterprises"
                :key="enterprise.id"
                v-bind:value="enterprise.id"
              >
                {{ enterprise.razonSocial }}
              </option>
            </select>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12" xs="12">
          <v-card-title>
            <router-link to="/addEmployee" id="nuevovacante">
              <button class="breakSearch botonAmarillo">
                Alta de Empleado
              </button>
            </router-link>
            <v-text-field
              class="mr-2"
              v-model="search"
              append-icon="search"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row no-gutters class="datos pa-0" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="employees"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Empleados por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5, 10],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.id }}</td>
                  <td class="text-sm-center">
                    {{ item.nombre + " " + item.apellidoPaterno }}
                  </td>
                  <td class="text-sm-center">{{ item.rfc }}</td>
                  <td class="text-sm-center">{{ item.area.nombreArea }}</td>
                  <td class="text-sm-center">
                    {{
                      item.sucursal != null ? item.sucursal.nombreSucursal : ""
                    }}
                  </td>
                  <td class="justify-center layout px-0">
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="editItem(item)"
                      >fa-edit</v-icon
                    >
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="viewItem(item)"
                      >fa-eye</v-icon
                    >
                    <v-icon
                      color="red"
                      small
                      class="mr-6"
                      @click="temporal(item)"
                      >fa-trash-alt</v-icon
                    >
                    <v-icon color="#717171" small @click="documents(item)"
                      >fas fa-file-import</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="advice = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="deleteItem()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Empleado</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DIALOG DE DATOS-->
    <v-dialog v-model="dialog" scrollable max-width="410px">
      <v-card>
        <v-card-title class="orange white--text"
          >Información de Empleado</v-card-title
        >
        <v-card-text style="height: 300px" id="info">
          <div class="form-group mt-5">
            <label>Numero de Empleado:</label>
            {{ employeeNumber }}
          </div>
          <div class="form-group">
            <label>Nombre:</label>
            {{ name }}
          </div>
          <div class="form-group">
            <label>Primer Apellido:</label>
            {{ surname }}
          </div>
          <div class="form-group">
            <label>Segundo Apellido:</label>
            {{ lastName }}
          </div>
          <div class="form-group">
            <label>Fecha de Nacimiento:</label>
            {{ date }}
          </div>
          <div class="form-group">
            <label>Género:</label>
            {{ gender }}
          </div>
          <div class="form-group">
            <label>RFC:</label>
            {{ rfc }}
          </div>
          <div class="form-group">
            <label>CURP:</label>
            {{ curp }}
          </div>
          <div class="form-group">
            <label>NSS:</label>
            {{ imss }}
          </div>
          <div class="form-group">
            <label>Telefóno Personal:</label>
            {{ telefonoPersonal }}
          </div>
          <div class="form-group">
            <label>Telefóno Oficina:</label>
            {{ telefono }} EXT.
            {{ ext }}
          </div>
          <div class="form-group">
            <label>Correo electrónico:</label>
            {{ correo }}
          </div>
          <div class="form-group">
            <label>Puesto:</label>
            {{ job }}
          </div>
          <div class="form-group">
            <label>Area:</label>
            {{ area }}
          </div>
          <div class="form-group">
            <label>Empresa:</label>
            {{ enterpriseEmpleado }}
          </div>
          <div class="form-group">
            <label>Fecha de Admision:</label>
            {{ dateAdmission }}
          </div>
          <div class="form-group">
            <label>Antiguedad:</label>
            {{ labor }} año(s)
          </div>
          <div class="form-group">
            <label>Tipo de Contrato:</label>
            {{ contract }}
          </div>
          <div class="form-group">
            <label>Tipo de Jornada:</label>
            {{ day }}
          </div>
          <div class="form-group">
            <label>Riesgo de Trabajo:</label>
            {{ risk }}
          </div>
          <div class="form-group">
            <label>Sindicalizado:</label>
            {{ union }}
          </div>
          <div class="form-group">
            <label>Regimen:</label>
            {{ regime }}
          </div>
          <div class="form-group">
            <label>Periodicidad de Pago:</label>
            {{ periodicity }}
          </div>
          <div class="form-group">
            <label>Banco:</label>
            {{ bank }}
          </div>
          <div class="form-group">
            <label>Cuenta:</label>
            {{ count }}
          </div>
          <div class="form-group">
            <label>Clave interbancaria:</label>
            {{ key }}
          </div>
          <div class="form-group">
            <label>Anticipo de Nomina:</label>
            {{ nomina }}
          </div>
          <div class="form-group">
            <label>Sueldo:</label>
            {{ sueldo }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      headers: [
        {
          text: "N° Empleado",
          align: "center",
          value: "id",
        },
        {
          text: "Nombre",
          align: "center",
          value: "nombre",
        },
        {
          text: "RFC",
          align: "center",
          value: "rfc",
        },
        {
          text: "Area/Departamento",
          align: "center",
          value: "area.nombreArea",
        },
        {
          text: "Sucursal",
          align: "center",
          value: "sucursal.nombreSucursal",
        },
        {
          text: "Editar  /   Ver  / Eliminar  / Documentos",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      employees: [],
      employeeNumber: 0,
      ext: "",
      telefono: "",
      telefonoPersonal: "",
      correo: "",
      name: "",
      surname: "",
      lastName: "",
      date: "",
      gender: 0,
      rfc: "",
      curp: "",
      imss: "",
      job: "",
      area: "",
      enterprise: localStorage.empresaIdGlobal,
      enterpriseEmpleado: "",
      dateAdmission: "",
      labor: "",
      contract: 0,
      day: 0,
      risk: 0,
      union: 0,
      regime: 0,
      periodicity: 0,
      bank: 0,
      count: "",
      key: "",
      nomina: 0,
      sueldo: 0,
      enterprises: [],
      respuesta: "",
      advice: false,
      id: "",
      confirmation: false,
      edit: false,
      vacio: false,
    };
  },
  methods: {
    documents(item) {
      localStorage.empresa = item.empresaId;
      localStorage.area = item.areaId;
      localStorage.puesto = item.puestoId;
      localStorage.employeeNumber = item.id;
      this.$router.push("/documents");
    },
    editItem(item) {
      //console.log("Ingreso a editar" + item.id);
      localStorage.employeeNumber = item.id;
      this.$router.push("/employeeUpdate");
    },
    temporal(item) {
      this.id = item.id;
      this.name =
        item.nombre + " " + item.apellidoMaterno + " " + item.apellidoPaterno;
      this.respuesta =
        "¿ Seguro que desea eliminar el empleado " + this.name + " ?";
      this.advice = true;
    },
    deleteItem() {
      this.advice = false;
      //console.log("Ingreso a eliminar el dato con id: " + this.id);
      this.respuesta = "";
      this.show = true;
      axios
        .delete(Server + "/empleados/" + this.id, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.id = "";
          this.respuesta = "El elemento fue eliminado con éxito";
          this.obtenerEmpresa();
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.id = "";
            this.respuesta = "El elemento no fue eliminado";
            this.confirmation = true;
          }
        });
    },
    format_date(fecha1, fecha2) {
      //console.log("fecha pasada" + fecha1);
      var aux = fecha1.substr(0, 10);
      //console.log("fecha obtenida" + aux);
      var aux2 = fecha2.substr(0, 10);
      this.date = aux;
      this.dateAdmission = aux2;
      return true;
    },
    //recupera los datos para el detalle del empleado
    viewItem(item) {
      //console.log("Ingreso a detalle", item);
      this.correo = item.email;
      this.employeeNumber = item.id;
      this.name = item.nombre;
      this.lastName = item.apellidoPaterno;
      this.surname = item.apellidoMaterno;
      this.date = item.fechaNacimiento;
      this.gender = item.genero == null ? "" : item.genero.nombreGenero;
      this.rfc = item.rfc;
      this.curp = item.curp;
      this.imss = item.nss;
      this.job = item.puesto.nombrePuesto;
      this.area = item.area.nombreArea;
      this.enterpriseEmpleado = item.empresa.razonSocial;
      this.dateAdmission = item.fechaAdmision;
      this.labor = item.cfdiEmpleado.antiguedad;
      this.contract = item.cfdiEmpleado.contrato.tipoContrato;
      this.day = item.cfdiEmpleado.jornada.nombreJornada;
      this.risk = item.cfdiEmpleado.riesgo.nombreRiesgo;
      this.union = item.cfdiEmpleado.sindicalizado;
      this.telefonoPersonal = item.telefonoCelular;
      if (item.sucursal == null) {
        this.telefono = "0000000000";
      } else {
        this.telefono = item.sucursal.telefono;
      }
      this.email = item.email;
      this.ext = item.telefonoExtension;
      if (this.union === false) {
        this.union = "No";
      } else {
        this.union = "Si";
      }
      this.regime = item.cfdiEmpleado.regimen.nombreRegimen;
      this.periodicity = item.cfdiEmpleado.periodicidadPago.nombrePeriodicidad;
      this.bank = item.datosBancoEmpleado.banco.nombreCorto;
      this.count = item.datosBancoEmpleado.cuenta;
      this.key = item.datosBancoEmpleado.cuentaClabe;
      this.sueldo = item.sueldo;
      this.nomina = item.datosBancoEmpleado.anticipoNomina;
      if (this.nomina === false) {
        this.nomina = "No";
      } else {
        this.nomina = "Si";
      }
      this.format_date(this.date, this.dateAdmission);
      this.dialog = true;
    },
    //obtiene los empleados por empresa
    obtenerEmpresa() {
      this.employees = [];
      this.show = true;
      axios
        .get(Server + "/empleados/por-empresa/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.employees = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log("Error", e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    //obtiene todas las empresas
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.listar();
    this.obtenerEmpresa();
  },
};
</script>